.img_presentacion{
    height: 100vh;
    width:100%;
    background-image: url("../../img/presentacion.jpg");
    overflow-y: auto;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.presentacion{
    color: var(--verde);  
    background-color: black;
    font-size: xx-large;
    display:flex;
    margin-top: 10%;
    text-align: center;
}



@media only screen and (max-width: 800px) {

    .presentacion{
        font-size: large;
    }
    
}