@import '../App.css';

.filterBtn{
    background-color: black;
    color: white;
    margin-left: 3em;
    margin-right: 3em;
    border-radius: 8%;
    border-color: white;
    padding: 0.2em 1em;
    font-size: 0.8em;
}

.filterBtn:focus {
    color:var(--verde);
    outline: none;
}


.filterBar{
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1%;
    padding-top: 0%;
}



/*---MEDIA QUERIES---------------------------------------------------------------*/


@media only screen and (max-width: 800px) {

    .filterBtn{
        margin-left: 0.1em;
        margin-right: 0.1em;
        font-size: 0.8em;
        padding: 0.2em 0.4em;
    }

    .filterBar{
        padding-bottom: 3%;
        justify-content: space-evenly;
    }


}
