@import '../App.css';

.overlay {
    visibility: hidden;
    z-index: 2;
    opacity: 1;
    transition: opacity 50ms;
    position: fixed;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }
  
  .popup {
    padding: 3%;
    background: var(--blanco);
    border-radius: 5px;
    position: relative;
    transition: all ease-in-out;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    overflow-y: scroll;
  }
  
  .popup h1 {
    margin-top: 0;
    color: #333;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .popup p {
    margin-top: 0;
    color: #333;
  }

  .popup_titulo{
    font-size: 1.2rem;
    margin-bottom: 0;
    font-weight: bold;
  }

  .popup_fecha{
    margin-bottom: 0.5rem;
    font-size: 0.8rem;  
    text-align: left;
  }

  .popup_cuerpo{
    font-size: 1rem;  
    line-height: 1.5;    
  }

  .popup_tituloBold{
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 0;
  }

  .popup_adicionales{
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  .popup .close {
    position: absolute;
    top: -6%;
    right: 0%;
    transition: all 200ms;
    font-size: 4rem;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    cursor: pointer;
    color:var(--verde);
  }

  .popup .share {
    position: absolute;
    top: 20%;
    right: 2%;
    transition: all 200ms;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .share:hover {
    cursor: pointer;
    color:var(--blue);
  }

  .popup .content {
    max-height: 100%;
    overflow: auto;
  }
    
  @media only screen and (max-width: 800px) {
    .popup h1 {
      font-size: 1rem;
    }

    .popup .close {
      font-size: 3.3rem;
      top: -2%;
      right: 1%;
    }

    .popup_cuerpo{
     margin-bottom: 0.5rem;   
    }



  }
  