:root {
  --oscuro:black; 
  --azul:#282c34;
  --amarillo: rgba(255, 255, 0, 0.923);
  --verde: rgb(204, 218, 36);
  --blanco:rgb(236, 233, 233);
}

.App{
  background-color: grey;
  display: flex;
  flex-direction: column;
  min-height: 100vh;  
}

/*---NAVBAR------------------------------------------------------------*/

.nav-logo{
  width:15%;
}

.mynav{
  background-color: var(--oscuro);
  padding: 0.5rem 1rem;
  min-width: 100%;
}

.mynavcollapse{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.mynavitems{
  text-align: right;
  font-size: 0.9rem;
}

.navbar-dark .navbar-nav .nav-link:hover{
   color:var(--verde);
  }

.hamburger{
  width: 1.2rem;
  height: 1.2rem;
}

/*---Seccion Informativa------------------------------------------------------------*/

.infoSection{
  flex: 1;
  align-items: center;  
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: lightgrey;
}

.popupholder{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 100%;  
  padding: 0%;
}

/*---Pagina con news---------------------------------------------------*/
/*El estilo esta definido en el mismo componente ListingPageComponent*/

/*---CARDS------------------------cursor: url(../public/cursor.png),pointer;--------------------*/

.flexContainer{
  display: flex;
  width: 50%;
  flex-direction:row;
  margin:0.5%;
  background-color: black;
  flex-wrap: nowrap;
  cursor:pointer;
}


.noticia{
  display: flex;
  flex-direction: column;
  background-color: var(--blanco);   
  min-width: 85%;
  max-width: 85%;
  padding: 1%;
  overflow: hidden;
  justify-content: space-between;
}

.noticia_titulo{
  margin-bottom: 0;
  font-size: 1.2rem;  
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */  
  white-space: pre-wrap; /* let the text wrap preserving spaces */
  margin-bottom: 0;
}

.noticia_descripcion{
  line-height: 1.5;
  font-size: 1rem;  
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3 ; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
  margin-bottom: 0;
}

.noticia_fecha{
  margin-bottom: 0;
  font-size: 0.8rem;  
  text-align: right;
}

.imagen{
  display: flex;
  width: 15%;
  height: auto;
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;
  background-color: var(--verde);
}

.img{
  object-fit: cover;
}

.scrollButton{
   position: fixed; 
   left: 90%;
   bottom: 10%;
   height: 70px;
   font-size: 3rem;
   z-index: 0;
   cursor: pointer;
   color: var(--verde);
}
/*---------------------------------------------------------------------------------*/

.footer{
  display: flex;
  gap: 0.5%;
  align-items: baseline;
  align-content: center;
  justify-content: center;
  background-color: var(--oscuro);
  color: aliceblue;
  font-size: 0.8rem;
  padding: 0.5%;
  text-align: center;
}


.footer_link{
  font-weight: bold;
  color: var(--verde);  
}


/*---MEDIA QUERIES---------------------------------------------------------------*/


@media only screen and (max-width: 800px) {

  .nav-logo{
    width:120px;
  }

  .mynavitems{
    font-size: 0.8rem;
    margin-top: 0.6rem;
  }


  .noticia_titulo{
    font-size: 0.9rem;
  }

  .noticia_descripcion{  
    font-size: 0.7rem;  
  }

  .noticia_fecha{
    font-size: 0.7rem;  
  }

  .flexContainer{
    min-width: 100%;
    height: 20vh;
    margin:1%;
  }

  .scrollButton{
    left:85%;
    width:50px;
    }  

  .footer{
    font-size: 0.7rem;
  }

}

@media (min-width: 800px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

}