.area{
    height: auto;
    min-height: 100vh;
    width:100%;
    background-color: white;
    display: flex;
    flex-direction: row;
}

.columnaImagen{
    background-color: white;
    height: auto;
    width: 15%;
    padding: 2%;
}

.columnaInfo{
    background-color: white;
    height: auto;
    width: 85%;
    padding: 2%;
    gap: 0%;
    display: flex;
    flex-direction: column;
}

.nota_titulo{
    margin-bottom: 0rem;
    font-size: 1.2rem;
}

.nota_descripcion{
    font-size: 1rem;  
    line-height: 1.5;
  }

.nota_cuerpo{
    font-size: 1rem;  
    line-height: 1.5;
  }
  
  .nota_fecha{
    margin-bottom: 0.5rem;
    font-size: 0.8rem;  
    text-align: left;
  }

  .nota_tituloBold{
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0;
  }


@media only screen and (max-width: 800px) {

    .area{
        flex-direction: column;
    }
    
    .imagenNoticia{
     display: none;
    }

    .columnaImagen{
        height: auto;
        width: 100%;
        padding: 2%;
        order:2;
    }  
    
  
    .columnaInfo{
        width: 100%;
        overflow-y: scroll;
        order:1;
    }

    .nota_titulo{
        margin-bottom: 0;
        font-size: 1rem;
    }
    
    .nota_descripcion{
        font-size: 0.8rem;  
      }
      
      .nota_fecha{
        font-size: 0.7rem;  
      }



      
}