.img_contacto{
    height: 100vh;
    width:100%;
    background-image: url("../../img/fondo.jpeg");
    overflow-y: auto;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}

.titulo_contacto{
    margin-left: 5%;
    margin-top: 5%;
    color: white;
    font-size: 1.4em;
}

.contacto{
    color: var(--verde);  
    margin-left: 5%;
    font-size: 1.4em;
}

.titulo_donaciones{
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 1%;
    color: white;
    font-size: 1.4em;
}

.link_cafecito{
    margin-left: 5%;    
}





@media only screen and (max-width: 800px) {

    h1{
        font-size: large;
    }

    .contacto{
        font-size: large;
    }
    
}