
.mediaShare{
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    margin-bottom: 2%;
    justify-content: left;
    flex-wrap: wrap;
    gap: 8px;
  }

.nota_compartir {
    transition: all 50ms;
    font-size: 1em;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    width: 1.8em;
    border-radius: 0.2em;
    background-color: white;
  }

  .nota_compartir:active {
    cursor: pointer;
    filter: invert(100%);
    /*background-color: var(--amarillo);*/
  }

  @media only screen and (max-width: 800px) {
  
    .mediaShare{
      display: flex;
      flex-direction: row;
      margin-top: 5%;
      margin-bottom: 2%;
      justify-content: left;
      flex-wrap: wrap;
      gap: 12px;
    }

  }